import {
  BehaviorSubjectSubscribe,
  createBehaviorSubject
} from 'packages/behavior-subject';
import { bsGetValue } from './get-value';

/**
 * `bsMap` is used to map a Behavior Subject to a new
 * value. It takes a function to convert the value
 *
 * Example Usage:
 *
 * ```ts
 * const [dispatch, subscribe] = createBehaviorSubject(2);
 *
 * const mappedValueSubscribe = bsMap(subscribe, (value) => value * 2)
 *
 * mappedValueSubscribe((result) => console.log(`Value: ${result}`));
 * // Logged: `Value: 4`
 *
 * dispatch(5);
 * // Logged: `Value: 10`
 *
 * dispatch(3);
 * // Logged:  `Value: 6`
 * ```
 */
export const bsMap = <Input, Output>(
  bsSubscribe: BehaviorSubjectSubscribe<Input>,
  mappingFn: (input: Input) => Output
): BehaviorSubjectSubscribe<Output> => {
  const [dispatch, subscribe] = createBehaviorSubject(
    mappingFn(bsGetValue(bsSubscribe))
  );
  bsSubscribe((nextValue) => dispatch(mappingFn(nextValue)));
  return subscribe;
};
