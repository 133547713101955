import {
  BehaviorSubjectSubscribe,
  createBehaviorSubject
} from 'packages/behavior-subject';

/**
 * `bsSwitchMap` is used to map a Behavior Subject to a new
 * value when the new value must be processed async. Meaning,
 * it is the same as `bsMap`, but the mappingFn can return a promise.
 * Results will occur in order. If new results are available before old
 * the old results will be skipped
 *
 * Example Usage:
 *
 * ```ts
 * const [dispatch, subscribe] = createBehaviorSubject(123);
 *
 * const apiProcessedResultSubscribe = await bsSwitchMap(subscribe, async (value) => {
 *  // Do some async work, like contact an API
 *  const response = await fetch(`/api/process-value/${value}`);
 *  return await response.json();
 * })
 *
 * apiProcessedResultSubscribe((mostRecentApiResult) => console.log(mostRecentApiResult));
 *
 *
 * dispatch(nextValue);
 * ```
 */
export const bsSwitchMap = async <Input, Output>(
  bsSubscribe: BehaviorSubjectSubscribe<Input>,
  mappingFn: (input: Input) => Promise<Output>,
  handleError: (error: any) => void
): Promise<BehaviorSubjectSubscribe<Output>> =>
  new Promise((resolve) => {
    let activeValue = Symbol();
    const [dispatch, subscribe] = createBehaviorSubject<Output>(null as any);

    bsSubscribe((value) => {
      const myTracker = (activeValue = Symbol());

      Promise.resolve(value)
        .then(mappingFn)
        .then(
          (mappedValue) => {
            // Make sure if a newer value comes in that we do not emit old values
            if (myTracker === activeValue) {
              dispatch(mappedValue);

              // The first time we dispatch a value, we can yield our BS now
              if (resolve) {
                resolve(subscribe);
                (resolve as any) = null;
              }
            }
          },

          (err) => handleError(err)
        );
    });
  });
