import { BehaviorSubjectSubscribe } from 'packages/behavior-subject';

/**
 * `bsGetValue` is used to get the singe current value of a behavior subject
 */
export const bsGetValue = <T>(bsSubscribe: BehaviorSubjectSubscribe<T>): T => {
  let unsubscribe = (): void => void 0;
  try {
    let value: T;
    unsubscribe = bsSubscribe((current) => (value = current));
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return value!;
  } finally {
    unsubscribe();
  }
};
